import './error.scss';
import ErrorImg from '../../resources/img/error.svg';

const Error = () => {
     const lang = localStorage.getItem('lang');

     return (
          <div className="error__container">
               <img src={ErrorImg} alt="Изображение ошибки" />
               <h2>{
                    lang === 'ru' ? 'Что-то пошло не так! Попробуйте обновить страницу!' :
                    lang === 'kz' ? 'Бірдеңе дұрыс болмады! Бетті жаңартып көріңіз!' :
                    'Something went wrong! Try refreshing the page!'
               }</h2>
          </div>
     )
}

export default Error;