import { Link } from "react-router-dom";
import sanitizeHtml from 'sanitize-html';

import axios from "../../services/backendService";

import './blogItem.scss';

const BlogItem = ({id, title, content, date, image, linkText}) => {
     const showCorrectDateFormat = (date) => {
          const day = date.substring(8, 10);
          const month = date.substring(5, 7);
          const year = date.substring(0, 4);
          return `${day}/${month}/${year}`;
     }

     const newContent = sanitizeHtml(content, {
          allowedTags: [], 
          allowedAttributes: {}, 
     });
     
     const showShortContent = (txt) => {
          const arrayOfText = sanitizeHtml(txt, {
               allowedTags: [], 
               allowedAttributes: {}, 
          }).split(' ');
          let num = 0;
          let text = '';
          for (let i = 0; i < arrayOfText.length; i++) {
               num += arrayOfText[i].length;
               if (num < 100) {
                    text += `${arrayOfText[i]} `;
               }
          }
          if (num >= 100) {
               text = text.substring(0, text.length - 1);
               text += '...';
          }
          return text;
     }

     return (
          <div className="s4__blog-item">
               <div className="s4__blog-item-img">
                    <img src={`${axios.defaults.baseURL}/uploads/blog/${image}`} alt="Новость"/>
               </div>
               <div className="s4__blog-item-content">
                    <h5 className="s4__blog-item-title">
                         {title}
                    </h5>
                    <div className="s4__blog-item-date">
                         <i className="fa-solid fa-calendar-days"></i>
                         {showCorrectDateFormat(date)}
                    </div>
                    <p className="s4__blog-item-text">
                         {
                              showShortContent(content)
                         }
                    </p>
                    <Link 
                         to={"/press-center/" + id} 
                         className="s4__blog-item-link">
                         {linkText}
                    </Link>
               </div>
          </div>
     );
}

export default BlogItem;