import { Link } from 'react-router-dom';

import './sidebarLink.scss';

const SidebarLink = ({link, ruName, kzName, enName, handleFunction, parts, currentLink}) => {
     const lang = localStorage.getItem('lang');
     let classNames = "page__sidebar-link";
     if (link === currentLink.substring(1)) {
          classNames += " page__sidebar-link-active";
     }

     if (parts && parts.some(part => `/part/${part.link}` === currentLink)) {
          classNames += " page__sidebar-link-active";
     }

     const classNamesOfChildren = "page__sidebar-link page__sidebar-link-child";

     return (
          parts && parts.length === 0 ? (
               <Link to={link} className={classNames}>
                    <div className="page__sidebar-link-border"></div>
                    {
                         lang === 'ru' ? ruName :
                         lang === 'kz' ? kzName :
                         enName
                    }
               </Link>
          ) : (
               <>
                    <div className={classNames} onClick={handleFunction}>
                         <div className="page__sidebar-link-border"></div>
                         {
                              lang === 'ru' ? ruName :
                              lang === 'kz' ? kzName :
                              enName
                         }
                    </div>   
                    <div className="page__sidebar-links">{
                         parts.map(part => (
                              <Link to={`/part/${part.link}`} className={classNamesOfChildren + (currentLink.substring(6) === part.link ? " page__sidebar-link-active" : "")}>
                                   <div className="page__sidebar-link-border"></div>
                                   {
                                        lang === 'ru' ? part.title.ru :
                                        lang === 'kz' ? part.title.kz :
                                        part.title.en
                                   }
                              </Link>
                         ))
                    }</div>
               </>
          )
          
     );
}

export default SidebarLink;