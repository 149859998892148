import { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import axios from '../../services/backendService';

import Background from '../../components/background/background';
import Loader from '../../components/loader/Loader';
import Error from '../../components/error/Error';

const PotentialPartnersPage = () => {

     const location = useLocation();
     const [error, setError] = useState(null);
     const [link, setLink] = useState(location.pathname);
     const [isLoading, setIsLoading] = useState(true);
     const [generalInformation, setGeneralInformation] = useState({});
     const [data, setData] = useState(null);
     const [lang, setLang] = useState(localStorage.getItem('lang'));

     console.log(link);

     useEffect(() => {
          setLink(location.pathname);
     }, [location.pathname]);
     useEffect(() => {
          setIsLoading(true);
          const fetchData = async () => {
               try {
                    const responseGeneralInformation = await axios.get('/general');
                    const responsePage = await axios.get(`${axios.defaults.baseURL}/documents/parent/64402667114cf634f7f998c9`);
                    setGeneralInformation(responseGeneralInformation.data);
                    setData(responsePage.data);
               } catch (error) {
                    setError(error);
               }
               setIsLoading(false);
          };
          fetchData();
          
     }, [link]);

     useEffect(() => {
          window.scrollTo(0, 0);
          const observer = new MutationObserver((mutations) => {
               mutations.forEach((mutation) => {
                    if (mutation.attributeName === 'lang') {
                         localStorage.setItem('lang', mutation.target.getAttribute('lang'));
                         setLang(localStorage.getItem('lang'));
                    }
               });
          });
      
          observer.observe(document.documentElement, { attributes: true, attributeFilter: ['lang'] });
          return () => {
               observer.disconnect();
          };
     }, []);

     useEffect(() => {
          if (data) {
               document.title = `${
                    lang === 'ru' ? 'Для потенциальных партнеров' :
                    lang === 'kz' ? 'Әлеуетті серіктестер үшін' :
                    'For potential partners'
               } - HAO Turar Healthcare`;
          }
     }, [isLoading, lang]);


     if (isLoading) {
          return (
               <Loader/>
          )
     }


     if (error) {
          return (
               <Error/>
          )
     }
     console.log(data);
     return (
          <>
               <Background
                    bgImage={generalInformation.bgImage}
                    link={null}
                    nestedRuTitle={null}
                    nestedKzTitle={null}
                    nestedEnTitle={null}
                    parentRuTitle='Для потенциальных партнеров'
                    parentKzTitle='Әлеуетті серіктестер үшін'
                    parentEnTitle='For potential partners'/>
               <div className="container">
                    <div className="nested__wrapper">{
                         <ol className="documents__list">{
                              data.map(doc => (
                                   <li className="documents__item">
                                        <Link
                                             to={`${axios.defaults.baseURL}/uploads/documents/${doc.filename}`}
                                             rel="noopener noreferrer"
                                             target="_blank"
                                             >
                                             {
                                                  lang === 'ru' ? doc.name.ru :
                                                  lang === 'kz' ? doc.name.kz :
                                                  doc.name.en
                                             }
                                        </Link>
                                   </li>
                              ))
                         }</ol>
                    }</div>
               </div>
          </>
     )
}

export default PotentialPartnersPage;