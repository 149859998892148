import { Link } from "react-router-dom";

import axios from "../../services/backendService";
import backgroundPlug from '../../resources/img/section_img.jpg';

const Background = ({
     bgImage,
     link,
     nestedRuTitle,
     nestedKzTitle,
     nestedEnTitle,
     parentRuTitle,
     parentKzTitle,
     parentEnTitle
}) => {
     const lang = localStorage.getItem('lang');
     return (
          <section 
               className="section"
               style={{'backgroundImage': `linear-gradient(180deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${bgImage ? `${axios.defaults.baseURL}/uploads/backgrounds/${bgImage}` : backgroundPlug})`}}>
               <div className="section__nav">
                    <Link to="/" className="section__nav-link">{
                         lang === 'ru' ? 'главная' : 
                         lang === 'kz' ? 'бастысы' : 
                         'main page'
                    }</Link>
                    <span className="section__nav-separator">/</span>
                    <p className="section__nav-link">{
                         lang === 'ru' ? parentRuTitle : 
                         lang === 'kz' ? parentKzTitle : 
                         parentEnTitle
                    }</p>
                    {
                         link && (
                              <>
                                   <span className="section__nav-separator">/</span>
                                   <Link to={link} className="section__nav-link">{
                                        lang === 'ru' ? nestedRuTitle : 
                                        lang === 'kz' ? nestedKzTitle : 
                                        nestedEnTitle
                                   }</Link>
                              </>
                         )
                    }
               </div>
          </section>
     )
}

export default Background;