import { Link } from "react-router-dom";
import axios from "../../services/backendService";

const PartnerItem = ({image, kz, ru, en, link}) => {
     const lang = localStorage.getItem('lang');
     return (
          <acronym title={
               lang === 'ru' ? ru :
               lang === 'kz' ? kz :
               en
          }>
               {
                    link ? (
                         <Link
                              to={link}
                              rel="noopener noreferrer"
                              target="_blank">
                              <img src={`${axios.defaults.baseURL}/uploads/partners/${image}`} alt={
                                   lang === 'ru' ? ru :
                                   lang === 'kz' ? kz :
                                   en
                              }/>
                         </Link>
                    ) : (
                         <img src={`${axios.defaults.baseURL}/uploads/partners/${image}`} alt={
                              lang === 'ru' ? ru :
                              lang === 'kz' ? kz :
                              en
                         }/>
                    )
               }
          </acronym>
     )
}

export default PartnerItem;