import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import axios from '../../services/backendService';

import logo from '../../resources/img/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, } from '@fortawesome/free-brands-svg-icons';
import './header.scss';

const Header = () => {
     const [lang, setLang] = useState(localStorage.getItem('lang'));
     const [generalInformation, setGeneralInformation] = useState({});
     const [parentPages, setParentPages] = useState([]);
     const [isLoading, setIsLoading] = useState(true);
     const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
     useEffect(() => {
          const fetchData = async () => {
               try {
                    const responseGeneralInformation = await axios.get('/general');
                    const responseParentPages = await axios.get('/page/parent');
                    
                    setGeneralInformation(responseGeneralInformation.data);
                    setParentPages(responseParentPages.data);
                    setIsLoading(false);
                    console.log(generalInformation);
                    console.log(parentPages);
               } catch (error) {
                 console.log(error);
               }
          };
          fetchData();
     }, [lang]);
     const changeLanguage = (langKey) => {
          localStorage.setItem('lang', langKey);
          setLang(localStorage.getItem('lang'));
          document.documentElement.setAttribute('lang', localStorage.getItem('lang'));     
     }

     const hideAllLinks = () => {
          document.querySelectorAll('.nav__dropdown-list').forEach(dropdown => {
               dropdown.classList.remove('nav__dropdown-list-show');
          });
          if (window.innerWidth <= 1000) {
               setMobileMenuIsOpen(false);
               document.querySelector('.nav__wrapper').classList.remove('nav__wrapper-show');
          }
     }
     
     const toggleNavLink = (event) => {
          let target = event.target.parentNode.lastChild.classList;
          if (event.target.classList.contains('fa-chevron-down')) {
               target = event.target.parentNode.parentNode.lastChild.classList;
          }
          if (target.contains('nav__dropdown-list-show')) {
               target.remove('nav__dropdown-list-show');
          } else {
               document.querySelectorAll('.nav__dropdown-list').forEach(dropdown => {
                    dropdown.classList.remove('nav__dropdown-list-show');
               });
               target.add('nav__dropdown-list-show');
          }
     }

     const toggleMobileMenu = (event) => {
          console.log(event.target);
          let wrapper = event.target.parentNode.parentNode.nextSibling;
          if (event.target.classList.contains('fa-solid fa-bars')) {
               wrapper = event.target.parentNode.parentNode.parentNode.nextSibling;
          }
          console.log(wrapper);
          setMobileMenuIsOpen(!mobileMenuIsOpen);
          if (mobileMenuIsOpen) {
               wrapper.classList.remove('nav__wrapper-show');
          } else {
               wrapper.classList.add('nav__wrapper-show');
          }
     }
     return (
          <header className="header">
               <div className="container">
                    <div className="header__wrapper">
                         <div className="header__part">
                              <Link 
                                   to="/" 
                                   className="header__home-link">
                                   <img src={logo} alt="logo" className="header__home-img"/>
                              </Link>
                         </div>
                         <div className="header__part">
                              <div className="header__social-links">
                                   <Link
                                        to={isLoading ? 'https://www.instagram.com/' : generalInformation.instagramLink}
                                        className="header__social-link"
                                        id="instagram"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <FontAwesomeIcon icon={faInstagram} />
                                   </Link>
                              </div>
                              <div className="header__languages">
                                   <div 
                                        className="header__languages-bg" 
                                        id="lang-bg"
                                        style={
                                             lang === 'ru' ? {'left':'50%', 'transform':'translateX(-50%)'} : 
                                             lang === 'kz' ? {'left':'0'} : 
                                             {'right':'0'}
                                        }>

                                   </div>
                                   {/* <div className="header__languages-bg" style={lang === 'ru' ? {'right':0} : {'left': 0}}></div> */}
                                   <div 
                                        className="header__languages-item" 
                                        onClick={() => changeLanguage('kz')}
                                        style={lang === 'kz' ? {'color':'#fff'} : {'color': '#576996'}}>
                                        KZ
                                   </div>
                                   <div 
                                        className="header__languages-item"
                                        onClick={() => changeLanguage('ru')}
                                        style={lang === 'ru' ? {'color':'#fff'} : {'color': '#576996'}}>
                                        RU
                                   </div>
                                   <div 
                                        className="header__languages-item"
                                        onClick={() => changeLanguage('en')}
                                        style={lang === 'en' ? {'color':'#fff'} : {'color': '#576996'}}>
                                        EN
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
               <nav className="nav">
                    <div className="container">
                         <div className="nav__mobile">
                             <div className="nav__mobile-btn" onClick={toggleMobileMenu}>
                                   <i class="fa-solid fa-bars"></i>
                             </div>
                         </div>
                         <div className="nav__wrapper">
                              {
                                   isLoading ? null : (
                                        <>
                                             <Link to="/" className="nav__link" onClick={hideAllLinks}>{
                                                  lang === 'ru' ? 'Главная страница' :
                                                  lang === 'kz' ? 'Басты бет' :
                                                  'Main Page'
                                             }</Link>
                                             {
                                                  parentPages.map((obj) => (
                                                       obj.isNested ? (
                                                            <div 
                                                                 className="nav__link nav__dropdown-toggle" 
                                                                 key={obj._id}>
                                                                 <div className="nav__dropdown-link"
                                                                      onClick={(e) => toggleNavLink(e)}>
                                                                 {
                                                                      lang === 'ru' ? obj.title.ru :
                                                                      lang === 'kz' ? obj.title.kz :
                                                                      obj.title.en
                                                                 }
                                                                 <FontAwesomeIcon icon={faChevronDown} />
                                                                 </div>
                                                                 <div className="nav__dropdown-list">
                                                                      {
                                                                           obj.nestedPages
                                                                           .map((item) => (
                                                                                <Link 
                                                                                     to={
                                                                                          item.parts && item.parts.length !== 0 
                                                                                          ? `/part/${item.parts[0].link}`
                                                                                          : item.link
                                                                                     }
                                                                                     key={item._id}
                                                                                     onClick={hideAllLinks}
                                                                                     className="nav__dropdown-item">{
                                                                                          lang === 'ru' ? item.title.ru :
                                                                                          lang === 'kz' ? item.title.kz :
                                                                                          item.title.en
                                                                                     }</Link>
                                                                           ))
                                                                      }
                                                                 </div>
                                                            </div>
                                                       ) : (
                                                            <Link to={`/${obj.link}`} className="nav__link" onClick={hideAllLinks} key={obj._id}>{
                                                                 lang === 'ru' ? obj.title.ru :
                                                                 lang === 'kz' ? obj.title.kz :
                                                                 obj.title.en
                                                            }</Link>
                                                       )
                                                  ))
                                             }
                                             {
                                                  window.innerWidth <= 1600 && window.innerWidth > 1000 ? (
                                                       <div 
                                                            className="nav__link nav__dropdown-toggle">
                                                            <div className="nav__dropdown-link"
                                                                 onClick={(e) => toggleNavLink(e)}>
                                                            {
                                                                 lang === 'ru' ? 'Еще' :
                                                                 lang === 'kz' ? 'Толығырақ' :
                                                                 'More'
                                                            }
                                                            <FontAwesomeIcon icon={faChevronDown} />
                                                            </div>
                                                            <div className="nav__dropdown-list">
                                                                 <Link 
                                                                      to="/press-center"
                                                                      onClick={hideAllLinks}
                                                                      className="nav__dropdown-item">{
                                                                           lang === 'ru' ? 'пресс-центр' :
                                                                           lang === 'kz' ? 'баспасөз орталығы' :
                                                                           'press-center'
                                                                      }</Link>
                                                                 <Link 
                                                                      to="/blog"
                                                                      onClick={hideAllLinks}
                                                                      className="nav__dropdown-item">{
                                                                           lang === 'ru' ? 'Блог председателя' :
                                                                           lang === 'kz' ? 'Төраға блогы' :
                                                                           'Chairman`s blog'
                                                                      }</Link>
                                                                 <Link 
                                                                      to="/contacts"
                                                                      onClick={hideAllLinks}
                                                                      className="nav__dropdown-item">{
                                                                           lang === 'ru' ? 'контакты' :
                                                                           lang === 'kz' ? 'байланыс' :
                                                                           'contacts'
                                                                      }</Link>
                                                            </div>
                                                       </div>
                                                  ) : (
                                                       <>
                                                            <Link to="/press-center" onClick={hideAllLinks} className="nav__link">{
                                                                 lang === 'ru' ? 'пресс-центр' :
                                                                 lang === 'kz' ? 'баспасөз орталығы' :
                                                                 'press-center'
                                                            }</Link>
                                                            <Link to="/blog" onClick={hideAllLinks} className="nav__link">{
                                                                 lang === 'ru' ? 'Блог председателя' :
                                                                 lang === 'kz' ? 'Төраға блогы' :
                                                                 'Chairman`s blog'
                                                            }</Link>
                                                            <Link to="/contacts" onClick={hideAllLinks} className="nav__link">{
                                                                 lang === 'ru' ? 'контакты' :
                                                                 lang === 'kz' ? 'байланыс' :
                                                                 'contacts'
                                                            }</Link>
                                                       </>
                                                       
                                                  )
                                             }
                                             
                                             
                                        </>
                                   )
                              }
                         </div>
                    </div>
               </nav>
          </header>
     );
}

export default Header;