import { Link } from 'react-router-dom';

import NotFoundImg from '../../resources/img/page_not_found.svg';
import './pageNotFound.scss';

const PageNotFound = ({title}) => {
     const lang = localStorage.getItem('lang');
     return (
          <div className="page__not-found">
               <div className="page__not-found-img">
                    <img src={NotFoundImg} alt="Not found" />
               </div>
               <p>
                    {title}<br/>
                    {lang === 'ru' && 'Перейти на '}
                    {lang === 'en' && 'Go to the '}
                    <Link to="/">{
                         lang === 'ru' ? 'Главную страницу' :
                         lang === 'kz' ? 'Басты бетке ' :
                         'Main Page'
                    }</Link>
                    {lang === 'kz' && 'өту'}
               </p>
               
          </div>
     )
}

export default PageNotFound;