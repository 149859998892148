import { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import parse from 'html-react-parser';
import axios from '../../services/backendService';

//import blankImg from '../../resources/img/blank1.svg';
import Background from '../../components/background/background';
import Loader from '../../components/loader/Loader';
import SidebarLink from '../../components/sidebarLink/SidebarLink';
import Error from '../../components/error/Error';


const CareerPage = () => {

     const location = useLocation();
     const [error, setError] = useState(null);
     const [link, setLink] = useState(location.pathname);
     const [isLoading, setIsLoading] = useState(true);
     const [generalInformation, setGeneralInformation] = useState({});
     const [vacancies, setVacancies] = useState({});
     const [contests, setContests] = useState({});
     const [isVacancies, setIsVacancies] = useState(true);
     const [lang, setLang] = useState(localStorage.getItem('lang'));

     useEffect(() => {
          setIsLoading(true);
          const fetchData = async () => {
               try {
                    const responseGeneralInformation = await axios.get('/general');
                    const responseVacancies = await axios.get(`${axios.defaults.baseURL}/vacancies`);
                    const responseContests = await axios.get(`${axios.defaults.baseURL}/contests`);
                    setGeneralInformation(responseGeneralInformation.data);
                    setVacancies(responseVacancies.data);
                    setContests(responseContests.data);
                    setIsLoading(false);
               } catch (error) {
                    setError(error);
               }
          };
          fetchData();
          
     }, [link]);

     useEffect(() => {
          window.scrollTo(0, 0);
          const observer = new MutationObserver((mutations) => {
               mutations.forEach((mutation) => {
                    if (mutation.attributeName === 'lang') {
                         localStorage.setItem('lang', mutation.target.getAttribute('lang'));
                         setLang(localStorage.getItem('lang'));
                    }
               });
          });
      
          observer.observe(document.documentElement, { attributes: true, attributeFilter: ['lang'] });
          return () => {
               observer.disconnect();
          };
     }, []);

     const handleClickToggleLinks = (event) => {
          if (event.target.id === 'vacancies') {
               setIsVacancies(true);
               document.querySelector('.page__sidebar-link-child:first-child').classList.add('page__sidebar-link-active');
               document.querySelector('.page__sidebar-link-child:last-child').classList.remove('page__sidebar-link-active');
          } else {
               setIsVacancies(false);
               document.querySelector('.page__sidebar-link-child:first-child').classList.remove('page__sidebar-link-active');
               document.querySelector('.page__sidebar-link-child:last-child').classList.add('page__sidebar-link-active');
          }
     }

     useEffect(() => {
          document.title = `${lang === 'ru' ? 'Карьера' : lang === 'kz' ? 'Карьера' : 'Career'} - HAO Turar Healthcare`;
     }, [lang]);

     if (isLoading) {
          return (
               <Loader/>
          )
     }

     if (error) {
          return (
               <Error/>
          )
     }
     return (
          <>
               <Background
                    bgImage={generalInformation.bgImage}
                    link="/career"
                    nestedRuTitle="Карьера"
                    nestedKzTitle="Карьера"
                    nestedEnTitle="Career"
                    parentRuTitle={vacancies.parent.title.ru}
                    parentKzTitle={vacancies.parent.title.kz}
                    parentEnTitle={vacancies.parent.title.en}/>
               <div className="container">
                    <div className="nested__wrapper">
                         <div className="nested__part">
                              {
                                   vacancies.parent.nestedPages
                                   .filter(it => {
                                        return it._id !== '641c9dd0812f801bbb67cec3' && it._id !== '641c9e22812f801bbb67d0f6'
                                   })
                                   .map((obj) => (
                                        <SidebarLink
                                             key={obj._id}
                                             link={`${window.location.origin}/${obj.link}`}
                                             ruName={obj.title.ru}
                                             kzName={obj.title.kz}
                                             enName={obj.title.en}
                                             classNames={
                                                  link.substring(1) === obj.link ? "page__sidebar-link page__sidebar-link-active"
                                                  : "page__sidebar-link"
                                             }/>
                                   ))
                              }
                              {
                                   vacancies.parent.link === 'about' && (
                                        <SidebarLink
                                             key={1}
                                             link={`${window.location.origin}/career`}
                                             ruName="Карьера"
                                             kzName="Карьера"
                                             enName="Career"
                                             classNames="page__sidebar-link page__sidebar-link-active"
                                             />
                                   )
                              }
                              <div className="page__sidebar-links">
                                   {
                                        vacancies.parent.nestedPages
                                        .filter(it => {
                                             return it._id === '641c9dd0812f801bbb67cec3' || it._id === '641c9e22812f801bbb67d0f6'
                                        })
                                        .map((obj) => (
                                             <SidebarLink
                                                  key={obj._id}
                                                  link={null}
                                                  ruName={obj.title.ru}
                                                  kzName={obj.title.kz}
                                                  enName={obj.title.en}
                                                  classNames={
                                                       obj._id === '641c9dd0812f801bbb67cec3' ?
                                                       "page__sidebar-link page__sidebar-link-child page__sidebar-link-active" :
                                                       "page__sidebar-link page__sidebar-link-child" 
                                                  }
                                                  handleFunction={handleClickToggleLinks}
                                                  id={obj.link}/>
                                        ))
                                   }
                              </div>
                         </div>
                         <div className="nested__part">
                              <div className="page">
                                   {/* <div className="page__title">
                                        <div className="section__title">
                                             <img src={blankImg} className="section__title-img" alt=""/>
                                             <h3 className="section__title-txt">{
                                                  lang === 'ru' ? data.data.title.ru : 
                                                  lang === 'kz' ? data.data.title.kz : 
                                                  data.data.title.en
                                             }</h3>
                                             <div className="section__title-border"></div>
                                        </div>
                                   </div> */}
                                   <div className="page__content">
                                        {
                                             isVacancies ? (
                                                  parse(
                                                       lang === 'ru' ? vacancies.data.content.ru : 
                                                       lang === 'kz' ? vacancies.data.content.kz : 
                                                       vacancies.data.content.en
                                                  )
                                             ) : (
                                                  <ol className="documents__list">{
                                                       contests.data.documents.map(doc => (
                                                            <li className="documents__item">
                                                                 <Link
                                                                      to={`${axios.defaults.baseURL}/uploads/documents/${doc.filename}`}
                                                                      rel="noopener noreferrer"
                                                                      target="_blank"
                                                                      >
                                                                      {
                                                                           lang === 'ru' ? doc.name.ru :
                                                                           lang === 'kz' ? doc.name.kz :
                                                                           doc.name.en
                                                                      }
                                                                 </Link>
                                                            </li>
                                                       ))
                                                  }</ol>
                                             )
                                        }
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </>
     )
}

export default CareerPage;