import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from '../../services/backendService';

import './footer.scss';
import footerLogo from '../../resources/img/footer_logo.png';

const Footer = () => {
     const [isLoading, setIsLoading] = useState(true);
     const [parentPages, setParentPages] = useState([]);
     const [lang, setLang] = useState(localStorage.getItem('lang'));
     useEffect(() => {
          const fetchData = async () => {
               const responsePages = await axios.get('/page/parent');
               setParentPages(responsePages.data);
               setIsLoading(false);
          }
          fetchData();

          const observer = new MutationObserver((mutations) => {
               mutations.forEach((mutation) => {
                    if (mutation.attributeName === 'lang') {
                         localStorage.setItem('lang', mutation.target.getAttribute('lang'));
                         setLang(localStorage.getItem('lang'));
                    }
               });
          });
      
          observer.observe(document.documentElement, { attributes: true, attributeFilter: ['lang'] });
          return () => {
               observer.disconnect();
          };
     }, []);
     return (
          <footer className="footer">
               <div className="container">
                    <div className="footer__wrapper">
                         <div className="footer__part">
                              <Link to="/" key={11}>
                                   <img src={footerLogo} alt="Логотип компании" className="footer__logo"/>
                              </Link>
                         </div>
                         {
                              isLoading ? (
                                   null
                              ) : (
                                   parentPages.map((obj, index) => (
                                        obj.isNested ? (
                                             <div className="footer__part">
                                                  <h6 className="footer__title">{
                                                       lang === 'ru' ? obj.title.ru :
                                                       lang === 'kz' ? obj.title.kz :
                                                       obj.title.en     
                                                  }</h6>
                                                  {
                                                       obj.nestedPages
                                                       .map(link => (
                                                            <Link to={
                                                                      link.parts && link.parts.length !== 0 
                                                                      ? `/part/${link.parts[0].link}`
                                                                      : link.link
                                                                 } 
                                                                 className="footer__link">{
                                                                 lang === 'ru' ? link.title.ru :
                                                                 lang === 'kz' ? link.title.kz :
                                                                 link.title.en     
                                                            }</Link>
                                                       ))
                                                  }
                                             </div>
                                        ) : (
                                             null
                                        )
                                   ))
                              )
                         }
                         <div className="footer__part">
                              <Link to="/press-center" className="footer__title">{
                                   lang === 'ru' ? 'Пресс-центр' :
                                   lang === 'kz' ? 'Баспасөз орталығы' :
                                   'Press center'
                              }</Link>
                         </div>
                         <div className="footer__part">
                              <Link to="/blog" className="footer__title">{
                                   lang === 'ru' ? 'Блог председателя' :
                                   lang === 'kz' ? 'Төраға блогы' :
                                   'Chairman`s blog'
                              }</Link>
                         </div>
                         <div className="footer__part">
                              <Link to="/contacts" className="footer__title">{
                                   lang === 'ru' ? 'Контакты' :
                                   lang === 'kz' ? 'Байланыс' :
                                   'Contacts'
                              }</Link>
                         </div>
                    </div>
               </div>
          </footer>
     );
}

export default Footer;