import { useState, useEffect } from 'react';
import axios from '../../services/backendService';

import BlogItem from '../../components/blogItem/BlogItem';
import Background from '../../components/background/background';
import Loader from '../../components/loader/Loader';
import Error from '../../components/error/Error';
import NotFound from '../../components/notFound/NotFound';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import './blogPage.scss';

const BlogPage = () => {
     const [lang, setLang] = useState(localStorage.getItem('lang'));
     const [isLoading, setIsLoading] = useState([]);
     const [error, setError] = useState(null);
     const [generalInformation, setGeneralInformation] = useState({});
     const [articles, setArticles] = useState([]);
     const [search, setSearch] = useState('');
     useEffect(() => {
          const fetchData = async () => {
               try {
                    const responseGeneralInformation = await axios.get('/general');
                    const responseArticles = await axios.get('/blog');
                    setGeneralInformation(responseGeneralInformation.data);
                    setArticles(responseArticles.data);
                    setIsLoading(false);
               } catch (err) {
                    setError(err);
               }
          }
          fetchData();
          window.scrollTo(0, 0);
          const observer = new MutationObserver((mutations) => {
               mutations.forEach((mutation) => {
                    if (mutation.attributeName === 'lang') {
                         localStorage.setItem('lang', mutation.target.getAttribute('lang'));
                         setLang(localStorage.getItem('lang'));
                    }
               });
          });
          
          observer.observe(document.documentElement, { attributes: true, attributeFilter: ['lang'] });
          return () => {
               observer.disconnect();
          };
     }, []);

     useEffect(() => {
          document.title = `${lang === 'ru' ? 'Пресс-центр' : lang === 'kz' ? 'Баспасөз орталығы' : 'Press center'} - HAO Turar Healthcare`;
     }, [lang]);

     const handleChangeSearch = (event) => {
          setSearch(event.target.value);
     }

     const filtered = articles.filter(item => {
          const valueTitle = lang === 'ru' ? item.title.ru : lang === 'kz' ? item.title.kz : item.title.en;
          const valueContent = lang === 'ru' ? item.content.ru : lang === 'kz' ? item.content.kz : item.content.en;
          return valueTitle.toLowerCase().includes(search.toLowerCase()) || valueContent.toLowerCase().includes(search.toLowerCase());
     });

     if (isLoading) {
          return (
               <Loader/>
          )
     }

     if (error) {
          return (
               <Error/>
          )
     }

     return (
          <>
               <Background
                    bgImage={generalInformation.bgImage}
                    link={null}
                    nestedRuTitle={null}
                    nestedKzTitle={null}
                    nestedEnTitle={null}
                    parentRuTitle="пресс-центр"
                    parentKzTitle="баспасөз орталығы"
                    parentEnTitle="press center"/>
               <div className="container">
                    <div className="blog__search">
                         <input 
                              type="text"
                              placeholder={
                                   lang === 'ru' ? 'Поиск...' :
                                   lang === 'kz' ? 'Іздеу...' :
                                   'Search...'
                              }
                              value={search}
                              onChange={handleChangeSearch} />
                         <FontAwesomeIcon icon={faSearch} />
                    </div>
                    <div className="blog__grid"
                         style={filtered.length === 0 ? {'display':'block'} : {'display':'grid'}}>
                         {
                              filtered.length === 0 ? (
                                   <NotFound
                                        title={
                                             lang === 'ru' ? 'Не удалось найти записи по данному запросу' :
                                             lang === 'kz' ? 'Осы сұраныс бойынша жазбаларды табылмады' :
                                             'Couldn`t find records for this query'
                                        }/>
                              ) : (
                                   filtered.map(obj => (
                                        <BlogItem
                                             key={obj._id}
                                             id={obj._id}
                                             title={
                                                  lang === 'ru' ? obj.title.ru : 
                                                  lang === 'kz' ? obj.title.kz : 
                                                  obj.title.en
                                             }
                                             content={
                                                  lang === 'ru' ? obj.content.ru : 
                                                  lang === 'kz' ? obj.content.kz : 
                                                  obj.content.en
                                             }
                                             date={obj.createdAt}
                                             image={obj.imageUrl}
                                             linkText={
                                                  lang === 'ru' ? 'Подробнее' : 
                                                  lang === 'kz' ? 'Толығырақ' :
                                                  'More'
                                             }
                                             />
                                        )
                                   )
                              )
                         }
                    </div>
               </div>
          </>
     )
}

export default BlogPage;