import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import parse from 'html-react-parser';
import axios from '../../services/backendService';

import Background from "../../components/background/background";
import Loader from "../../components/loader/Loader";
import BlogItem from "../../components/blogItem/BlogItem";
import NotFound from "../../components/notFound/NotFound";

import './cityPage.scss';

const CityPage = () => {
     const {city} = useParams();
     const [isLoading, setIsLoading] = useState(true);
     const [generalInformation, setGeneralInformation] = useState({});
     const [data, setData] = useState({});
     const [isInfo, setIsInfo] = useState(true);
     const [lang, setLang] = useState(localStorage.getItem('lang'));

     const handleChangeToogleInfo = (event) => {
          if (event.target.id === "blog") {
               setIsInfo(false);
          } else {
               setIsInfo(true);
          }
     }

     useEffect(() => {
          const fetchData = async () => {
               try {
                    const responseGeneralInformation = await axios.get('/general');
                    const responseCity = await axios.get(`/city/${city}`);
                    setGeneralInformation(responseGeneralInformation.data);
                    setData(responseCity.data);
                    setIsLoading(false);
               } catch (error) {
                    console.log(error);
               }
          }
          fetchData();

          window.scrollTo(0, 0);

          const observer = new MutationObserver((mutations) => {
               mutations.forEach((mutation) => {
                    if (mutation.attributeName === 'lang') {
                         localStorage.setItem('lang', mutation.target.getAttribute('lang'));
                         setLang(localStorage.getItem('lang'));
                    }
               });
          });
      
          observer.observe(document.documentElement, { attributes: true, attributeFilter: ['lang'] });
          return () => {
               observer.disconnect();
          };
     }, []);

     if (isLoading) {
          return (
               <Loader/>
          )
     }

     return (
          <>
               <Background
                    bgImage={generalInformation.bgImage}
                    link={`/${data.link}`}
                    nestedRuTitle={data.name.ru}
                    nestedKzTitle={data.name.kz}
                    nestedEnTitle={data.name.en}
                    parentRuTitle="карта объектов"
                    parentKzTitle="объекттер картасы"
                    parentEnTitle="map of objects"/>
               <div className="container">
                    <div className="city__wrapper">
                         <div className="city__btn-list">
                              <div className={isInfo ? "city__btn-item city__btn-item-active" : "city__btn-item"}
                                   id="info"
                                   onClick={handleChangeToogleInfo}>
                                   {
                                        lang === 'ru' ? 'О городе' : 
                                        lang === 'kz' ? 'Қала туралы' : 
                                        'About city'
                                   }
                              </div>
                              <div className={isInfo ? "city__btn-item" : "city__btn-item city__btn-item-active"}
                                   id="blog"
                                   onClick={handleChangeToogleInfo}>
                                   {
                                        lang === 'ru' ? 'Пресс-центр' : 
                                        lang === 'kz' ? 'Баспа орталығы' : 
                                        'Press center'
                                   }
                              </div>
                         </div>
                         <div className="city__content"
                              style={isInfo ? {'display':'block'} : {'display':'none'}}>{
                              parse(
                                   lang === 'ru' ? data.content.ru :
                                   lang === 'kz' ? data.content.kz :
                                   data.content.en
                              )
                         }</div>
                         <div className="city__blog"
                              style={
                                   isInfo ? {'display':'none'} :
                                   data.blog.length === 0 ? {'display':'block'} :
                                   {'display': window.innerWidth <= 600 ? 'flex' : 'grid'}
                              }>{
                              data.blog.length > 0 ? (
                                   data.blog.map((obj) => (
                                        <BlogItem
                                             key={obj._id}
                                             id={obj._id}
                                             title={
                                                  lang === 'ru' ? obj.title.ru : 
                                                  lang === 'kz' ? obj.title.kz : 
                                                  obj.title.en
                                             }
                                             content={
                                                  lang === 'ru' ? obj.content.ru : 
                                                  lang === 'kz' ? obj.content.kz : 
                                                  obj.content.en
                                             }
                                             date={obj.createdAt}
                                             image={obj.imageUrl}
                                             linkText={
                                                  lang === 'ru' ? 'Подробнее' : 
                                                  lang === 'kz' ? 'Толығырақ' :
                                                  'More'
                                             }/>
                                   ))
                              ) : (
                                   <NotFound
                                        title={
                                             lang === 'ru' ? 'Еще нету статьей в пресс-центре связанного с этим городом' : 
                                             lang === 'kz' ? 'Бұл қалаға қатысты баспасөз орталығында әлі мақала жоқ' :
                                             'There is still no article in the press center associated with this city'          
                                        }/>
                              )
                         }</div>
                    </div>
               </div>
          </>
          
     )
}

export default CityPage;