import { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from '../../services/backendService';

import './chairmanBlogItem.scss';

const ChairmanBlogItem = ({obj, isAnswered}) => {
     const lang = localStorage.getItem('lang');
     const [isMore, setIsMore] = useState(false);
     const [answerIsShow, setAnswerIsShow] = useState(false);
     const handleChangeMore = () => {
          setIsMore(!isMore);
     }
     const handleChangeAnswer = () => {
          setAnswerIsShow(!answerIsShow);
     }

     console.log(obj);

     const showCorrectDateFormat = (dateStr) => {
          const date = new Date(dateStr);
          const day = date.getDate();
          const month = date.getMonth() + 1;
          const year = date.getFullYear();
          const hour = date.getHours();
          const minutes = date.getMinutes();
          return `${getZero(day)}.${getZero(month)}.${getZero(year)} ${getZero(hour)}:${getZero(minutes)}`;
     }
     const getZero = (num) => (num > 9 ? ""+ num : "0"+num);

     const showShortContent = (txt) => {
          const arrayOfText = txt.split(' ');
          let num = 0;
          let text = '';
          for (let i = 0; i < arrayOfText.length; i++) {
               num += arrayOfText[i].length;
               if (num < 100) {
                    text += `${arrayOfText[i]} `;
               }
          }
          if (num >= 100) {
               text = text.substring(0, text.length - 1);
               text += '...';
          }
          return text;
     }
     return (
          <div className="chairman__item">
               <div className="chairman__item-header">
                    <div className="chairman__item-header-part">
                         <div className="chairman__item-header-item">
                              <i class="fa-solid fa-hashtag"></i>
                              <p>015-11.{obj.mailId}</p>
                         </div>
                         <div className="chairman__item-header-item">
                              <i class="fa-solid fa-user"></i>
                              <p>{obj.senderData.name} {obj.senderData.surname}</p>
                         </div>
                         <div className="chairman__item-header-item">
                              <i class="fa-solid fa-at"></i>
                              <p>{obj.senderData.email}</p>
                         </div>
                         <div className="chairman__item-header-item">
                              <i class="fa-regular fa-calendar"></i>
                              <p>{showCorrectDateFormat(obj.createdAt)}</p>
                         </div>
                    </div>
                    {
                    !isAnswered && (
                         <div className="chairman__item-header-part">{
                              obj.status === 1 ? (
                                   <div className="chairman__item-status chairman__item-status-blue">
                                        <p className="chairman__item-status-text">{
                                             lang === "ru" ? 'Обращение на модерации' :
                                             lang === "kz" ? 'Өтініш модерация кезеңінде' :
                                             'Request at the moderation'
                                        }</p>
                                   </div>
                              ) :
                              obj.status === 2 ? (
                                   <div className="chairman__item-status chairman__item-status-green">
                                        <p className="chairman__item-status-text">{
                                             lang === "ru" ? 'Обращение прошло модерацию' :
                                             lang === "kz" ? 'Өтініш модерациядан өтті' :
                                             'Request has passed moderation'
                                        }</p>
                                   </div>
                              ) :
                              (
                                   <div className="chairman__item-status chairman__item-status-red">
                                        <p className="chairman__item-status-text">{
                                             lang === "ru" ? 'Обращение не прошло модерацию' :
                                             lang === "kz" ? 'Өтініш модерациядан өтпеді' :
                                             'Request did not pass moderation'
                                        }</p>
                                   </div>
                              )
                         }</div>
                    )
               }
               </div>    
               <h2 className="chairman__item-title">Сообщение: </h2>           
               <p className="chairman__item-message">{
                    isMore ? obj.message : showShortContent(obj.message)  
               }</p>
               <div>
               {
                    obj.message.length > 100 && (
                         <span className="chairman__item-more" onClick={handleChangeMore}>{
                              lang === 'ru' ? 'Подробнее' :
                              lang === 'kz' ? 'Толығырақ' :
                              'More'
                         }</span>
                    )
               }
               {
                    isAnswered && (
                         <span className="chairman__item-more" onClick={handleChangeAnswer}>{
                              lang === 'ru' ? 'Показать ответ' :
                              lang === 'kz' ? 'Жауапты көрсету' :
                              'Show answer'
                         }</span>
                    )
               }     
               </div>
               {
                    obj.files.length > 0 && (
                         <>
                              <h2 className="chairman__item-title">Прикрепленные файлы:</h2>           
                              <ol>
                              {
                                   obj.files.map(file => (
                                        <li className="chairman__item-file">
                                             <Link
                                                  to={`${axios.defaults.baseURL}/uploads/mail/${file}`}>
                                                  {file}
                                             </Link>
                                        </li>
                                   ))
                              }
                              </ol>
                         </>
                    )
               }
               {
                    answerIsShow && (
                         <>
                              <h2 className="chairman__item-title">Ваш ответ: </h2>           
                              <p className="chairman__item-message">{obj.answer}</p>
                         </>
                    )
               }
               
          </div>

     )
}

export default ChairmanBlogItem;