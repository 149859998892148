import { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import parse from 'html-react-parser';


import axios from "../../services/backendService";
import Loader from "../../components/loader/Loader";
import Error from "../../components/error/Error";
import Background from "../../components/background/background";

import blogPlug from '../../resources/img/blog_plug.jpg'
import './articlePage.scss';

const ArticlePage = () => {
     const location = useLocation();
     const [isLoading, setIsLoading] = useState(true);
     const [error, setError] = useState(null);
     const [article, setArticle] = useState(null);
     const [articles, setArticles] = useState([]);
     const [generalInformation, setGeneralInformation] = useState({});
     const {id} = useParams();
     const [lang, setLang] = useState(localStorage.getItem('lang'));
     const [link, setLink] = useState(location.pathname);
     useEffect(() => {
          setLink(location.pathname);
     }, [location.pathname]);
     useEffect(() => {
          setIsLoading(true);
          const fetchData = async () => {
               try {
                    const responseGeneralInformation = await axios.get('/general');
                    const responseArticle = await axios.get(`/blog/${id}`);
                    const responseBlog = await axios.get(`/blog/latest/${id}`);
                    setGeneralInformation(responseGeneralInformation.data);
                    setArticle(responseArticle.data);
                    setArticles(responseBlog.data);
                    setIsLoading(false);
               } catch (error) {
                    setError(error);
               }
          }
          fetchData();

          window.scrollTo(0, 0);
          
          const observer = new MutationObserver((mutations) => {
               mutations.forEach((mutation) => {
                    if (mutation.attributeName === 'lang') {
                         localStorage.setItem('lang', mutation.target.getAttribute('lang'));
                         setLang(localStorage.getItem('lang'));
                    }
               });
          });
          
          observer.observe(document.documentElement, { attributes: true, attributeFilter: ['lang'] });
          return () => {
               observer.disconnect();
          };
     }, [link]);

     useEffect(() => {
          if (article) {
               document.title = `${lang === 'ru' ? article.title.ru : 
                              lang === 'kz' ? article.title.kz :
                              article.title.en} - HAO Turar Healthcare`;
          }
     }, [isLoading, lang]);

     const showCorrectDateFormat = (date) => {
          const day = date.substring(8, 10);
          const month = date.substring(5, 7);
          const year = date.substring(0, 4);
          return `${day}/${month}/${year}`;
     }

     if (error) {
          return (
               <Error/>
          )
     }
     
     if (isLoading) {
          return (
               <Loader/>
          )
     }

     return (
          <main> 
               <Background
                    bgImage={generalInformation.bgImage}
                    link={`/blog/${article._id}`}
                    nestedRuTitle={article.title.ru}
                    nestedKzTitle={article.title.kz}
                    nestedEnTitle={article.title.en}
                    parentRuTitle="пресс-центр"
                    parentKzTitle="баспасөз орталығы"
                    parentEnTitle="press center"/>
               <section className="article">
                    <div className="container">
                         <div className="article__wrapper">
                              <div className="article__part">
                                   <div className="article__img">
                                        <img src={`${axios.defaults.baseURL}/uploads/blog/${article.imageUrl}`} alt="Изображение статьи"/>
                                   </div>
                                   <h1 className="article__title">{
                                        lang === 'ru' ? article.title.ru : 
                                        lang === 'kz' ? article.title.kz : 
                                        article.title.en
                                   }</h1>
                                   <div className="article__date">
                                        <i className="fa-solid fa-calendar-days"></i>
                                        {showCorrectDateFormat(article.createdAt)}
                                   </div>
                                   <div className="article__content">
                                   {
                                        parse(
                                             lang === 'ru' ? article.content.ru : 
                                             lang === 'kz' ? article.content.kz : 
                                             article.content.en
                                        )
                                   }
                                   </div>
                              </div>
                              <div className="article__part">
                                   <h4 className="article__part-title">{
                                        lang === 'ru' ? 'Другие записи' : 
                                        lang === 'kz' ? 'Басқа жазбалар' : 
                                        'Other records'
                                   }</h4>
                                   {
                                        articles.map(item => (
                                             <Link className="article__item"
                                                  to={`/press-center/${item._id}`}>
                                                  <img src={`${axios.defaults.baseURL}/uploads/blog/${item.imageUrl}`} alt="Изображение статьи" className="article__item-img"/>
                                                  <div className="article__item-content">
                                                       <h6 className="article__item-content-title">{
                                                            lang === 'ru' ? item.title.ru :
                                                            lang === 'kz' ? item.title.kz :
                                                            item.title.en
                                                       }</h6>
                                                       <div className="article__item-content-date">
                                                            <i className="fa-solid fa-calendar-days"></i>
                                                            {
                                                                 showCorrectDateFormat(item.createdAt)
                                                            }
                                                       </div>
                                                  </div>
                                             </Link>
                                        ))
                                   }
                              </div>
                         </div>
                    </div>
               </section>   
          </main>
     )
}

export default ArticlePage;