import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import axios from '../../services/backendService';

import Loader from "../../components/loader/Loader";
import BlogItem from "../../components/blogItem/BlogItem";
import PartnerItem from "../../components/partnerItem/PartnerItem";
import Error from "../../components/error/Error";

import blankImg from '../../resources/img/blank1.svg';
import whiteArrow from '../../resources/img/arrow_down_circle_icon.png';
import blueArrow from '../../resources/img/arrow_down_circle_icon_blue.png';
import mapKazakhstan from '../../resources/img/map_kazakhstan.png';
import homeBackgroundPlug from '../../resources/img/main_page_header_img.jpg';
import './homePage.scss';

const HomePage = () => {
     const [generalInformation, setGeneralInformation] = useState({});
     const [isLoading, setIsLoading] = useState(true);
     const [articles, setArticles] = useState([]);
     const [partners, setPartners] = useState([]);
     const [cities, setCities] = useState([]);
     const [error, setError] = useState(null);
     const [lang, setLang] = useState(localStorage.getItem('lang'));
     const [indexOfCurrentSlider, setIndexOfCurrentSlider] = useState(0);
     const [numberOfSliders, setNumberOfSliders] = useState(0);
     const [sliderOffset, setSliderOffset] = useState(0);
     useEffect(() => {
          const fetchData = async () => {
               try {
                    const responseGeneralInformation = await axios.get('/general');
                    const responsePartners = await axios.get('/partners');
                    const responseCities = await axios.get('/city/all');
                    const responseArticles = await axios.get('/blog/latest');
                    setGeneralInformation(responseGeneralInformation.data);
                    setPartners(responsePartners.data);
                    setCities(responseCities.data);
                    setArticles(responseArticles.data);
                    setIsLoading(false);
               } catch (error) {
                 setError(error);
               }
          };
          fetchData();
          window.scrollTo(0, 0);
          document.title = `${lang === 'ru' ? 'Главная' : lang === 'kz' ? 'Бастысы' : 'Main Page'} - HAO Turar Healthcare`;

          const observer = new MutationObserver((mutations) => {
               mutations.forEach((mutation) => {
                    if (mutation.attributeName === 'lang') {
                         localStorage.setItem('lang', mutation.target.getAttribute('lang'));
                         setLang(localStorage.getItem('lang'));
                    }
               });
          });
          
          observer.observe(document.documentElement, { attributes: true, attributeFilter: ['lang'] });
          return () => {
               observer.disconnect();
          };
     }, []);

     useEffect(() => {
          document.title = `${lang === 'ru' ? 'Главная' : lang === 'kz' ? 'Бастысы' : 'Main Page'} - HAO Turar Healthcare`;
     }, [lang]);

     useEffect(() => {
          console.log(window.innerWidth);
          console.log(window.innerWidth > 240);
          if (!isLoading && window.innerWidth !== 0) {
               if (window.innerWidth > 1280 && window.innerWidth <= 1600) {
                    changeNumberOfFromSliderItems(4);
               } else if (window.innerWidth > 920 && window.innerWidth <= 1280) {
                    changeNumberOfFromSliderItems(3)
               } else if (window.innerWidth > 670 && window.innerWidth <= 920) {
                    changeNumberOfFromSliderItems(2);
               } else if (window.innerWidth > 420 && window.innerWidth <= 670) {
                    changeNumberOfFromSliderItems(1);
               } else if (window.innerWidth > 0 && window.innerWidth <= 420) {
                    console.log('object');
                    changeNumberOfFromSliderItems(0);
               } else {
                    changeNumberOfFromSliderItems(5);
               }
          }
     }, [isLoading, window.innerWidth]);
     console.log(numberOfSliders);

     const changeNumberOfFromSliderItems = (num) => {
          const width = document.querySelector('.s3__slider-list').clientWidth;
          let widthOfSliderItem = 200;
          let gapOfSliderList = 40;
          setSliderOffset(widthOfSliderItem + gapOfSliderList);
          setNumberOfSliders((width - ((partners.length - 1) * gapOfSliderList)) / widthOfSliderItem - num);
     }

     const moveSlider = (event) => {
          const target = event.target.classList;
          if ((target.contains('s3__slider-btn-left') || target.contains('fa-angle-left')) && indexOfCurrentSlider !== 0) {
               setIndexOfCurrentSlider(indexOfCurrentSlider - 1);
          } else if ((target.contains('s3__slider-btn-right') || target.contains('fa-angle-right')) && indexOfCurrentSlider !== numberOfSliders) {
               setIndexOfCurrentSlider(indexOfCurrentSlider + 1);
          }
     }

     if (error) {
          return (
               <Error/>
          )
     }
     
     if (isLoading) {
          return (
               <Loader/>
          )
     }

     return (
          <main>
               <section className="section-1" style={{'backgroundImage': `linear-gradient(180deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${generalInformation.homeBgImage ? `${axios.defaults.baseURL}/uploads/backgrounds/${generalInformation.homeBgImage}` : homeBackgroundPlug})`}}>
                    <div className="s1__wrapper">
                         <img className="s1__img" src={blankImg} alt=""/>
                         <h3 className="s1__title-additional">{
                              lang === 'ru' ? generalInformation.additionalTitle.ru : 
                              lang === 'kz' ? generalInformation.additionalTitle.kz : 
                              generalInformation.additionalTitle.en
                         }</h3>
                         <h1 className="s1__title-first">{generalInformation.firstTitle}</h1>
                         <div className="s1__border"></div>
                         <h2 className="s1__title-second">{
                              lang === 'ru' ? generalInformation.secondTitle.ru : 
                              lang === 'kz' ? generalInformation.secondTitle.kz : 
                              generalInformation.secondTitle.en
                         }</h2>
                         <Link to="/our-team" className="s1__btn">{
                              lang === 'ru' ? 'подробнее' :
                              lang === 'kz' ? 'толығырақ' :  
                              'more'
                         }</Link>
                         <HashLink to="#map" className="s1__link">
                              <img src={whiteArrow} className="s1__link-img" alt="Стрелка для перехода в следующую секцию"/>
                         </HashLink>
                    </div>
               </section>
               <section className="section-2" id="map">
                    <div className="container-small">
                         <div className="s2__wrapper">
                              <div className="section__title">
                                   <img src={blankImg} className="section__title-img" alt=""/>
                                   <h3 className="section__title-txt">{
                                        lang === 'ru' ? 'Карта объектов' : 
                                        lang === 'kz' ? 'Объектер картасы' : 
                                        'The map of objects'
                                   }</h3>
                                   <div className="section__title-border"></div>
                              </div>
                              <div className="s2__map">
                                   <img src={mapKazakhstan} alt="Карта Казахстана" className="s2__map-img"/>
                                   {
                                        cities.map((city) => (
                                             <Link 
                                                  to={`/city/${city.link}`} 
                                                  className="s2__map-city" 
                                                  style={{
                                                       'left': `${city.coordinates.horizontal}%`,
                                                       'top': `${city.coordinates.vertical}%`
                                             }}>
                                                  <h6 className="s2__map-city-name">{
                                                       lang === 'ru' ? city.name.ru :
                                                       lang === 'kz' ? city.name.kz :
                                                       city.name.en
                                                  }</h6>
                                                  
                                             </Link>
                                        ))
                                   }
                                   
                              </div>
                         </div>
                    </div>
                    <HashLink to="#partners" className="s2__link">
                         <img src={blueArrow} className="s2__link-img" alt="Стрелка для перехода в следующую секцию"/>
                    </HashLink>
               </section>
               <section className="section-3" id="partners"> 
                    <div className="container">
                         <div className="s3__wrapper">
                              <div className="section__title">
                                   <img src={blankImg} className="section__title-img" alt=""/>
                                   <h3 className="section__title-txt">{
                                        lang === 'ru' ? 'Список партнеров' : 
                                        lang === 'kz' ? 'Серіктестер тізімі' : 
                                        'The list of partners'
                                   }</h3>
                                   <div className="section__title-border"></div>
                              </div>
                              <div className="s3__slider">
                                   <div className="s3__slider-btn s3__slider-btn-left" onClick={moveSlider}>
                                        <i className="fa-solid fa-angle-left"></i>
                                   </div>
                                   <div className="s3__slider-container">
                                        <div className="s3__slider-list" 
                                             style={{'transform':`translateX(-${sliderOffset * indexOfCurrentSlider}px)`}}>
                                             {
                                                  partners.map((obj) => (
                                                       <PartnerItem
                                                            key={obj._id}
                                                            image={obj.imageUrl}
                                                            kz={obj.kzTitle}
                                                            ru={obj.ruTitle}
                                                            en={obj.enTitle}
                                                            link={obj.link}/>
                                                  ))     
                                             }
                                        </div>
                                   </div>
                                   <div className="s3__slider-btn s3__slider-btn-right" onClick={moveSlider}>
                                        <i className="fa-solid fa-angle-right"></i>
                                   </div>
                              </div>
                         </div>
                    </div>
               </section>

               <section className="section-4">
                    <div className="container">
                         <div className="s4__wrapper">
                              <div className="section__title">
                                   <img src={blankImg} className="section__title-img" alt=""/>
                                   <h3 className="section__title-txt">{
                                        lang === 'ru' ? 'Пресс-центр' :
                                        lang === 'kz' ? 'Баспасөз орталығы' :
                                        'Press center'
                                   }</h3>
                                   <div className="section__title-border"></div>
                              </div>
                              <div className="s4__blog-list">
                                   {
                                        articles.map((obj) => (
                                             <BlogItem
                                                  id={obj._id}
                                                  title={
                                                       lang === 'ru' ? obj.title.ru : 
                                                       lang === 'kz' ? obj.title.kz : 
                                                       obj.title.en
                                                  }
                                                  content={
                                                       lang === 'ru' ? obj.content.ru : 
                                                       lang === 'kz' ? obj.content.kz : 
                                                       obj.content.en
                                                  }
                                                  date={obj.createdAt}
                                                  image={obj.imageUrl}
                                                  linkText={
                                                       lang === 'ru' ? 'Подробнее' : 
                                                       lang === 'kz' ? 'Толығырақ' :
                                                       'More'
                                                  }
                                                  onChangeLoading={setIsLoading}
                                                  />
                                        ))
                                   }
                              </div>
                              <div className="s4__blog-link">
                                   <Link to="/press-center">{
                                        lang === 'ru' ? 'перейти в пресс-центр' :
                                        lang === 'kz' ? 'баспасөз орталығы бетін ашу' :
                                        'go to the press center'
                                   }</Link>
                              </div>
                         </div>
                    </div>
               </section>

               <section className="section-5">
                    <div className="container">
                         <div className="s5__wrapper">
                              <div className="s5__part">
                                   <iframe 
                                        title="Яндекс Карта"
                                        src="https://yandex.ru/map-widget/v1/?um=constructor%3Af8957fbae4f03b2f61117756e1e6f58052c6d8f86b79050118a0434af8ab92d2&amp;source=constructor"
                                        frameBorder="0"></iframe>
                              </div>
                              <div className="s5__part">
                                   <div className="section__title">
                                        <img src={blankImg} className="section__title-img" alt=""/>
                                        <h3 className="section__title-txt">{
                                             lang === 'ru' ? 'Контакты' :
                                             lang === 'kz' ? 'Байланыс' :
                                             'Contacts'
                                        }</h3>
                                        <div className="section__title-border"></div>
                                   </div>
                                   <div className="s5__info">
                                        <div className="s5__info-item">
                                             <i className="fa-solid fa-location-dot"></i>
                                             {
                                                  lang === 'ru' ? generalInformation.address.ru : 
                                                  lang === 'kz' ? generalInformation.address.kz : 
                                                  generalInformation.address.en
                                             }
                                        </div>
                                        <div className="s5__info-item">
                                             <i className="fa-solid fa-phone"></i>
                                             <Link to="#">{generalInformation.phoneNumber}</Link>
                                        </div>
                                        <div className="s5__info-item">
                                             <i className="fa-solid fa-envelope"></i>
                                             <Link to={"mailto:" + generalInformation.mail}>{generalInformation.mail}</Link>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </section>
          </main>
     );
}

export default HomePage;