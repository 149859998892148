import NotFoundImg from '../../resources/img/not_found.svg';

import './notFound.scss';

const NotFound = ({title}) => {
     return (
          <div className="not__found">
               <img src={NotFoundImg} alt="Not found" />
               <h6>{title}</h6>
          </div>
     )
}

export default NotFound;